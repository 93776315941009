/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import o from "../core/Error.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
let p = class extends r {
  constructor(t) {
    super(t), this.created = null, this.id = null, this.portal = null, this.title = null, this.username = null;
  }
  get url() {
    const t = this.portal?.restUrl;
    return t ? `${t}/content/users/${this.username}/${this.id}` : null;
  }
  toJSON() {
    throw new o("internal:not-yet-implemented", "PortalFolder.toJSON is not yet implemented");
  }
};
t([e({
  type: Date
})], p.prototype, "created", void 0), t([e()], p.prototype, "id", void 0), t([e()], p.prototype, "portal", void 0), t([e()], p.prototype, "title", void 0), t([e({
  readOnly: !0
})], p.prototype, "url", null), t([e()], p.prototype, "username", void 0), p = t([s("esri.portal.PortalFolder")], p);
const l = p;
export { l as default };