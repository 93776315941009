/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../config.js";
import { id as r } from "../kernel.js";
import o from "../request.js";
import s from "../core/Error.js";
import { JSONSupportMixin as i } from "../core/JSONSupport.js";
import a from "../core/Loadable.js";
import { destroyMaybe as l, removeMaybe as u } from "../core/maybe.js";
import { throwIfAborted as n, isAborted as p, createAbortError as d, throwIfAbortError as h } from "../core/promiseUtils.js";
import { property as y } from "../core/accessorSupport/decorators/property.js";
import { ensureType as c } from "../core/accessorSupport/ensureType.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { reader as m } from "../core/accessorSupport/decorators/reader.js";
import { subclass as f } from "../core/accessorSupport/decorators/subclass.js";
import v from "../geometry/Extent.js";
import { getLocale as S } from "../intl/locale.js";
import { ensureDefaultPortalInstance as P } from "./portalDefault.js";
import g from "./PortalGroup.js";
import O from "./PortalQueryParams.js";
import G from "./PortalQueryResult.js";
import B from "./PortalUser.js";
import { supportsApiKey as D } from "../support/apiKeyUtils.js";
var _;
let M;
const U = {
  PortalGroup: () => Promise.resolve({
    default: g
  }),
  PortalItem: () => import("./PortalItem.js"),
  PortalUser: () => Promise.resolve({
    default: B
  })
};
let b = _ = class extends i(a) {
  constructor(e) {
    super(e), this._esriIdCredentialCreateHandle = null, this.access = null, this.allSSL = !1, this.authMode = "auto", this.authorizedCrossOriginDomains = null, this.basemapGalleryGroupQuery = null, this.basemapGalleryGroupQuery3D = null, this.bingKey = null, this.canListApps = !1, this.canListData = !1, this.canListPreProvisionedItems = !1, this.canProvisionDirectPurchase = !1, this.canSearchPublic = !0, this.canShareBingPublic = !1, this.canSharePublic = !1, this.canSignInArcGIS = !1, this.canSignInIDP = !1, this.colorSetsGroupQuery = null, this.commentsEnabled = !1, this.created = null, this.culture = null, this.customBaseUrl = null, this.defaultBasemap = null, this.defaultDevBasemap = null, this.defaultExtent = null, this.defaultVectorBasemap = null, this.description = null, this.devBasemapGalleryGroupQuery = null, this.eueiEnabled = null, this.featuredGroups = null, this.featuredItemsGroupQuery = null, this.galleryTemplatesGroupQuery = null, this.layoutGroupQuery = null, this.livingAtlasGroupQuery = null, this.hasCategorySchema = !1, this.helperServices = null, this.homePageFeaturedContent = null, this.homePageFeaturedContentCount = null, this.httpPort = null, this.httpsPort = null, this.id = null, this.ipCntryCode = null, this.isPortal = !1, this.isReadOnly = !1, this.layerTemplatesGroupQuery = null, this.maxTokenExpirationMinutes = null, this.modified = null, this.name = null, this.portalHostname = null, this.portalMode = null, this.portalProperties = null, this.region = null, this.recycleBinEnabled = !1, this.rotatorPanels = null, this.showHomePageDescription = !1, this.sourceJSON = null, this.supportsHostedServices = !1, this.symbolSetsGroupQuery = null, this.templatesGroupQuery = null, this.units = null, this.url = t.portalUrl, this.urlKey = null, this.user = null, this.use3dBasemaps = !0, this.useStandardizedQuery = !1, this.useVectorBasemaps = !1, this.vectorBasemapGalleryGroupQuery = null;
  }
  normalizeCtorArgs(e) {
    return "string" == typeof e ? {
      url: e
    } : e;
  }
  destroy() {
    Q.unregister(this), this.defaultBasemap = l(this.defaultBasemap), this.defaultDevBasemap = l(this.defaultDevBasemap), this.defaultVectorBasemap = l(this.defaultVectorBasemap), this._esriIdCredentialCreateHandle = u(this._esriIdCredentialCreateHandle);
  }
  readAuthorizedCrossOriginDomains(e) {
    if (e) for (const r of e) t.request.trustedServers.includes(r) || t.request.trustedServers.push(r);
    return e;
  }
  readDefaultBasemap(e) {
    return this._readBasemap(e);
  }
  readDefaultDevBasemap(e) {
    return this._readBasemap(e);
  }
  readDefaultVectorBasemap(e) {
    return this._readBasemap(e);
  }
  get extraQuery() {
    const e = this.user?.orgId,
      t = !e || this.canSearchPublic;
    return this.id && !t ? ` AND orgid:${this.id}` : null;
  }
  get isOrganization() {
    return !!this.access;
  }
  get itemPageUrl() {
    return this.url ? `${this.url}/home/item.html` : null;
  }
  get restUrl() {
    let e = this.url;
    if (e) {
      const t = e.indexOf("/sharing");
      e = t > 0 ? e.substring(0, t) : this.url.replace(/\/+$/, ""), e += "/sharing/rest";
    }
    return e;
  }
  get thumbnailUrl() {
    const e = this.restUrl,
      t = this.thumbnail;
    return e && t ? this._normalizeSSL(e + "/portals/self/resources/" + t) : null;
  }
  readUrlKey(e) {
    return e ? e.toLowerCase() : e;
  }
  readUser(e) {
    let t = null;
    return e && (t = B.fromJSON(e), t.portal = this), t;
  }
  load(e) {
    const t = import("../Basemap.js").then(({
      default: t
    }) => {
      n(e), M = t;
    }).then(() => this.sourceJSON ? this.sourceJSON : this.fetchSelf(this.authMode, !1, e)).then(e => {
      if (r) {
        const e = r;
        this.credential = e.findCredential(this.restUrl), this.credential || this.authMode !== _.AUTH_MODE_AUTO && this.authMode !== _.AUTH_MODE_NO_PROMPT || (this._esriIdCredentialCreateHandle?.remove(), this._esriIdCredentialCreateHandle = e.on("credential-create", w(new WeakRef(this))), Q.register(this, this._esriIdCredentialCreateHandle, this));
      }
      this.sourceJSON = e, this.read(e);
    });
    return this.addResolvingPromise(t), Promise.resolve(this);
  }
  async createElevationLayers() {
    await this.load();
    const e = this._getHelperService("defaultElevationLayers"),
      t = (await import("../layers/ElevationLayer.js")).default;
    return e ? e.map(e => new t({
      id: e.id,
      url: e.url
    })) : [];
  }
  async fetchBasemaps(e, t) {
    const r = await this._fetchBasemaps(e, t);
    if (!0 === t?.include3d && !1 !== this.use3dBasemaps) {
      const o = await this._fetchBasemaps3D(e, t);
      r.unshift(...o);
    }
    return r;
  }
  fetchCategorySchema(e) {
    return this.hasCategorySchema ? this.request(this.restUrl + "/portals/self/categorySchema", e).then(e => e.categorySchema) : p(e) ? Promise.reject(d()) : Promise.resolve([]);
  }
  fetchFeaturedGroups(e) {
    const t = this.featuredGroups,
      r = new O({
        num: 100,
        sortField: "title"
      });
    if (t?.length) {
      const o = [];
      for (const e of t) o.push(`(title:"${e.title}" AND owner:${e.owner})`);
      return r.query = o.join(" OR "), this.queryGroups(r, e).then(e => e.results);
    }
    return p(e) ? Promise.reject(d()) : Promise.resolve([]);
  }
  fetchRegions(e) {
    const t = this.user?.culture || this.culture || S();
    return this.request(this.restUrl + "/portals/regions", {
      ...e,
      query: {
        culture: t
      }
    });
  }
  fetchSettings(e) {
    const t = this.user?.culture || this.culture || S();
    return this.request(this.restUrl + "/portals/self/settings", {
      ...e,
      query: {
        culture: t
      }
    });
  }
  static getDefault() {
    return P(() => new _());
  }
  queryGroups(e, t) {
    return this.queryPortal("/community/groups", e, "PortalGroup", t);
  }
  queryItems(e, t) {
    return this.queryPortal("/search", e, "PortalItem", t);
  }
  queryUsers(e, t) {
    return e.sortField || (e.sortField = "username"), this.queryPortal("/community/users", e, "PortalUser", t);
  }
  fetchSelf(e = this.authMode, t = !1, r) {
    const o = this.restUrl + "/portals/self",
      s = {
        authMode: e,
        query: {
          culture: S().toLowerCase()
        },
        withCredentials: !0,
        ...r
      };
    return "auto" === s.authMode && (s.authMode = "no-prompt"), t && (s.query.default = !0), this.request(o, s);
  }
  queryPortal(e, t, r, o) {
    const s = c(O, t),
      i = t => this.request(this.restUrl + e, {
        ...s.toRequestOptions(this),
        ...o
      }).then(e => {
        const r = s.clone();
        return r.start = e.nextStart, new G({
          nextQueryParams: r,
          queryParams: s,
          total: e.total,
          results: _._resultsToTypedArray(t, {
            portal: this
          }, e, o)
        });
      }).then(e => Promise.all(e.results.map(t => "function" == typeof t.when ? t.when() : e)).then(() => e, t => (h(t), e)));
    return r && U[r] ? U[r]().then(({
      default: e
    }) => (n(o), i(e))) : i();
  }
  signIn() {
    if (this.authMode === _.AUTH_MODE_ANONYMOUS || this.authMode === _.AUTH_MODE_NO_PROMPT && !r) return Promise.reject(new s("portal:invalid-auth-mode", `Current "authMode"' is "${this.authMode}"`));
    if ("failed" === this.loadStatus) return Promise.reject(this.loadError);
    const e = e => Promise.resolve().then(() => "not-loaded" === this.loadStatus ? (e || (this.authMode = "immediate"), this.load().then(() => null)) : "loading" === this.loadStatus ? this.load().then(() => this.credential ? null : (this.credential = e, this.fetchSelf("immediate"))) : this.user && this.credential === e ? null : (this.credential = e, this.fetchSelf("immediate"))).then(e => {
      e && (this.sourceJSON = e, this.read(e));
    });
    return r ? r.getCredential(this.restUrl, {
      prompt: this.authMode !== _.AUTH_MODE_NO_PROMPT
    }).then(t => e(t)) : e(this.credential);
  }
  normalizeUrl(e) {
    const t = this.credential?.token;
    return this._normalizeSSL(t ? e + (e.includes("?") ? "&" : "?") + "token=" + t : e);
  }
  requestToTypedArray(e, t, r) {
    return this.request(e, t).then(e => {
      const t = _._resultsToTypedArray(r, {
        portal: this
      }, e);
      return Promise.all(t.map(t => "function" == typeof t.when ? t.when() : e)).then(() => t, () => t);
    });
  }
  request(e, t = {}) {
    const r = {
        f: "json",
        ...t.query
      },
      {
        authMode: s = this.authMode === _.AUTH_MODE_ANONYMOUS || this.authMode === _.AUTH_MODE_NO_PROMPT ? this.authMode : "auto",
        body: i = null,
        cacheBust: a = !1,
        method: l = "auto",
        responseType: u = "json",
        signal: n
      } = t,
      p = {
        authMode: s,
        body: i,
        cacheBust: a,
        method: l,
        query: r,
        responseType: u,
        timeout: 0,
        signal: n
      };
    return t.withCredentials && (p.withCredentials = !0), o(this._normalizeSSL(e), p).then(e => e.data);
  }
  toJSON() {
    throw new s("internal:not-yet-implemented", "Portal.toJSON is not yet implemented");
  }
  static fromJSON(e) {
    if (!e) return null;
    if (e.declaredClass) throw new Error("JSON object is already hydrated");
    return new _({
      sourceJSON: e
    });
  }
  _getHelperService(e) {
    const t = this.helperServices && this.helperServices[e];
    if (!t) throw new s("portal:service-not-found", `The \`helperServices\` do not include an entry named "${e}"`);
    return t;
  }
  async _fetchBasemaps(e, r) {
    const o = new O();
    o.query = e || (t.apiKey && D(this.url) ? this.devBasemapGalleryGroupQuery : this.useVectorBasemaps ? this.vectorBasemapGalleryGroupQuery : this.basemapGalleryGroupQuery), o.disableExtraQuery = !0;
    const s = await this.queryGroups(o, r);
    if (!s.total) return [];
    const i = s.results[0];
    o.num = 100, o.query = 'type:"Web Map" -type:"Web Application"', o.sortField = i.sortField || "name", o.sortOrder = i.sortOrder || "desc";
    const a = await i.queryItems(o, r);
    if (!a.total) return [];
    return a.results.filter(e => "Web Map" === e.type).map(e => new M({
      portalItem: e
    }));
  }
  async _fetchBasemaps3D(e, t) {
    const r = e || this.basemapGalleryGroupQuery3D;
    if (!r) return [];
    const o = new O({
        query: r,
        disableExtraQuery: !0
      }),
      s = await this.queryGroups(o, t);
    if (!s.total) return [];
    const i = s.results[0];
    o.num = 100, o.query = 'type:"Web Scene"', o.sortField = i.sortField || "name", o.sortOrder = i.sortOrder || "desc";
    const a = await i.queryItems(o, t);
    if (!a.total) return [];
    return a.results.filter(e => "Web Scene" === e.type).map(e => new M({
      portalItem: e
    }));
  }
  _normalizeSSL(e) {
    return e.replace(/^http:/i, "https:").replace(":7080", ":7443");
  }
  _readBasemap(e) {
    if (e) {
      const t = M.fromJSON(e);
      return t.portalItem = {
        portal: this
      }, t;
    }
    return null;
  }
  static _resultsToTypedArray(e, t, r, o) {
    let s;
    if (r) {
      const i = null != o ? o.signal : null;
      s = r.listings || r.notifications || r.userInvitations || r.tags || r.items || r.groups || r.comments || r.provisions || r.results || r.relatedItems || r, (e || t) && (s = s.map(r => {
        const o = Object.assign(e ? e.fromJSON(r) : r, t);
        return "function" == typeof o.load && o.load(i), o;
      }));
    } else s = [];
    return s;
  }
};
b.AUTH_MODE_ANONYMOUS = "anonymous", b.AUTH_MODE_AUTO = "auto", b.AUTH_MODE_IMMEDIATE = "immediate", b.AUTH_MODE_NO_PROMPT = "no-prompt", e([y()], b.prototype, "access", void 0), e([y()], b.prototype, "allSSL", void 0), e([y()], b.prototype, "authMode", void 0), e([y()], b.prototype, "authorizedCrossOriginDomains", void 0), e([m("authorizedCrossOriginDomains")], b.prototype, "readAuthorizedCrossOriginDomains", null), e([y()], b.prototype, "basemapGalleryGroupQuery", void 0), e([y({
  json: {
    name: "3DBasemapGalleryGroupQuery"
  }
})], b.prototype, "basemapGalleryGroupQuery3D", void 0), e([y()], b.prototype, "bingKey", void 0), e([y()], b.prototype, "canListApps", void 0), e([y()], b.prototype, "canListData", void 0), e([y()], b.prototype, "canListPreProvisionedItems", void 0), e([y()], b.prototype, "canProvisionDirectPurchase", void 0), e([y()], b.prototype, "canSearchPublic", void 0), e([y()], b.prototype, "canShareBingPublic", void 0), e([y()], b.prototype, "canSharePublic", void 0), e([y()], b.prototype, "canSignInArcGIS", void 0), e([y()], b.prototype, "canSignInIDP", void 0), e([y()], b.prototype, "colorSetsGroupQuery", void 0), e([y()], b.prototype, "commentsEnabled", void 0), e([y({
  type: Date
})], b.prototype, "created", void 0), e([y()], b.prototype, "credential", void 0), e([y()], b.prototype, "culture", void 0), e([y()], b.prototype, "currentVersion", void 0), e([y()], b.prototype, "customBaseUrl", void 0), e([y()], b.prototype, "defaultBasemap", void 0), e([m("defaultBasemap")], b.prototype, "readDefaultBasemap", null), e([y()], b.prototype, "defaultDevBasemap", void 0), e([m("defaultDevBasemap")], b.prototype, "readDefaultDevBasemap", null), e([y({
  type: v
})], b.prototype, "defaultExtent", void 0), e([y()], b.prototype, "defaultVectorBasemap", void 0), e([m("defaultVectorBasemap")], b.prototype, "readDefaultVectorBasemap", null), e([y()], b.prototype, "description", void 0), e([y()], b.prototype, "devBasemapGalleryGroupQuery", void 0), e([y()], b.prototype, "eueiEnabled", void 0), e([y({
  readOnly: !0
})], b.prototype, "extraQuery", null), e([y()], b.prototype, "featuredGroups", void 0), e([y()], b.prototype, "featuredItemsGroupQuery", void 0), e([y()], b.prototype, "galleryTemplatesGroupQuery", void 0), e([y()], b.prototype, "layoutGroupQuery", void 0), e([y()], b.prototype, "livingAtlasGroupQuery", void 0), e([y()], b.prototype, "hasCategorySchema", void 0), e([y()], b.prototype, "helpBase", void 0), e([y()], b.prototype, "helperServices", void 0), e([y()], b.prototype, "helpMap", void 0), e([y()], b.prototype, "homePageFeaturedContent", void 0), e([y()], b.prototype, "homePageFeaturedContentCount", void 0), e([y()], b.prototype, "httpPort", void 0), e([y()], b.prototype, "httpsPort", void 0), e([y()], b.prototype, "id", void 0), e([y()], b.prototype, "ipCntryCode", void 0), e([y({
  readOnly: !0
})], b.prototype, "isOrganization", null), e([y()], b.prototype, "isPortal", void 0), e([y()], b.prototype, "isReadOnly", void 0), e([y({
  readOnly: !0
})], b.prototype, "itemPageUrl", null), e([y()], b.prototype, "layerTemplatesGroupQuery", void 0), e([y()], b.prototype, "maxTokenExpirationMinutes", void 0), e([y({
  type: Date
})], b.prototype, "modified", void 0), e([y()], b.prototype, "name", void 0), e([y()], b.prototype, "portalHostname", void 0), e([y()], b.prototype, "portalMode", void 0), e([y()], b.prototype, "portalProperties", void 0), e([y()], b.prototype, "region", void 0), e([y()], b.prototype, "recycleBinEnabled", void 0), e([y({
  readOnly: !0
})], b.prototype, "restUrl", null), e([y()], b.prototype, "rotatorPanels", void 0), e([y()], b.prototype, "showHomePageDescription", void 0), e([y()], b.prototype, "sourceJSON", void 0), e([y()], b.prototype, "staticImagesUrl", void 0), e([y({
  json: {
    name: "2DStylesGroupQuery"
  }
})], b.prototype, "stylesGroupQuery2d", void 0), e([y({
  json: {
    name: "stylesGroupQuery"
  }
})], b.prototype, "stylesGroupQuery3d", void 0), e([y()], b.prototype, "supportsHostedServices", void 0), e([y()], b.prototype, "symbolSetsGroupQuery", void 0), e([y()], b.prototype, "templatesGroupQuery", void 0), e([y()], b.prototype, "thumbnail", void 0), e([y({
  readOnly: !0
})], b.prototype, "thumbnailUrl", null), e([y()], b.prototype, "units", void 0), e([y()], b.prototype, "url", void 0), e([y()], b.prototype, "urlKey", void 0), e([m("urlKey")], b.prototype, "readUrlKey", null), e([y()], b.prototype, "user", void 0), e([m("user")], b.prototype, "readUser", null), e([y()], b.prototype, "use3dBasemaps", void 0), e([y()], b.prototype, "useStandardizedQuery", void 0), e([y()], b.prototype, "useVectorBasemaps", void 0), e([y()], b.prototype, "vectorBasemapGalleryGroupQuery", void 0), b = _ = e([f("esri.portal.Portal")], b);
const C = b,
  Q = new FinalizationRegistry(e => {
    e.remove();
  });
function w(e) {
  const t = r;
  return () => {
    const r = e.deref();
    r && t.findCredential(r.restUrl) && r.signIn().catch(() => {});
  };
}
export { C as default };