/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import o from "../core/Accessor.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
let e = class extends o {
  constructor(r) {
    super(r), this.nextQueryParams = null, this.queryParams = null, this.results = null, this.total = null;
  }
};
r([s()], e.prototype, "nextQueryParams", void 0), r([s()], e.prototype, "queryParams", void 0), r([s()], e.prototype, "results", void 0), r([s()], e.prototype, "total", void 0), e = r([t("esri.portal.PortalQueryResult")], e);
const p = e;
export { p as default };