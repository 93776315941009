/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import r from "../core/Accessor.js";
import { JSONMap as e } from "../core/jsonMap.js";
import { clone as s } from "../core/lang.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import a from "../geometry/Extent.js";
import n from "../geometry/SpatialReference.js";
import { project as l } from "../geometry/support/webMercatorUtils.js";
var u;
const m = new e({
  avgRating: "avg-rating",
  numRatings: "num-ratings",
  numComments: "num-comments",
  numViews: "num-views"
});
let p = u = class extends r {
  constructor(t) {
    super(t), this.categories = null, this.disableExtraQuery = !1, this.extent = null, this.filter = null, this.num = 10, this.query = null, this.sortField = null, this.start = 1;
  }
  get sortOrder() {
    return this._get("sortOrder") || "asc";
  }
  set sortOrder(t) {
    "asc" !== t && "desc" !== t || this._set("sortOrder", t);
  }
  clone() {
    return new u({
      categories: this.categories ? s(this.categories) : null,
      disableExtraQuery: this.disableExtraQuery,
      extent: this.extent ? this.extent.clone() : null,
      filter: this.filter,
      num: this.num,
      query: this.query,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      start: this.start
    });
  }
  toRequestOptions(t, r) {
    let e = [];
    this.categories && (e = this.categories.map(t => Array.isArray(t) ? JSON.stringify(t) : t));
    let s = "";
    if (this.extent) {
      const t = l(this.extent, n.WGS84);
      null != t && (s = `${t.xmin},${t.ymin},${t.xmax},${t.ymax}`);
    }
    let o = this.query;
    !this.disableExtraQuery && t.extraQuery && (o = "(" + o + ")" + t.extraQuery);
    const i = {
      categories: e,
      bbox: s,
      q: o,
      filter: this.filter,
      num: this.num,
      sortField: null,
      sortOrder: null,
      start: this.start
    };
    return this.sortField && (i.sortField = this.sortField.split(",").map(t => m.toJSON(t.trim())).join(","), i.sortOrder = this.sortOrder), {
      query: {
        ...r,
        ...i
      }
    };
  }
};
t([o()], p.prototype, "categories", void 0), t([o()], p.prototype, "disableExtraQuery", void 0), t([o({
  type: a
})], p.prototype, "extent", void 0), t([o()], p.prototype, "filter", void 0), t([o()], p.prototype, "num", void 0), t([o()], p.prototype, "query", void 0), t([o()], p.prototype, "sortField", void 0), t([o()], p.prototype, "sortOrder", null), t([o()], p.prototype, "start", void 0), p = u = t([i("esri.portal.PortalQueryParams")], p);
const c = p;
export { c as default };