/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
let t;
function e() {
  t = void 0;
}
function n(e) {
  return t && !t.destroyed || (t = e()), t;
}
export { e as clearDefaultPortalInstance, n as ensureDefaultPortalInstance };